<template>
  <div class="w-full h-full bg-neutral-0 p-6 space-y-6 relative">
    <div
      v-if="isOverviewLoading"
      class="flex h-full items-center justify-center absolute top-0 mx-auto w-full inset-0 bg-white z-50 bg-opacity-75 transition-opacity"
    >
      <brightbid-loader />
    </div>

    <infringement-banner
      v-if="infringementSummary?.newCount > 0 ?? false"
      :new-count="infringementSummary.newCount"
    />

    <dialog-box
      v-if="hasSavingsInsights"
      :savings-insights="savingsInsights"
    />
    <!--ROW 1-->
    <!-- TODO: create a component for these cards-->
    <div class="flex flex-col lg:flex-row gap-6">
      <!--COMPETITOR INSIGHTS-->
      <div class="text-bb-text-default px-5 py-6 bg-white w-full space-y-3 rounded-lg shadow-md">
        <h4 class="text-sm font-medium text-bb-text-default">Competitors Insights</h4>
        <div class="flex justify-evenly w-full p-4">
          <div
            v-for="(insight, index) in computedCompetitorInsights"
            :key="index"
            class="flex gap-2 items-center w-full"
          >
            <div>
              <div
                class="rounded-full h-50px w-50px flex items-center justify-center"
                :class="insight.iconBgColor"
              >
                <component
                  :is="insight.icon"
                  :class="[insight.iconColor, insight.iconClass]"
                />
              </div>
            </div>
            <div class="space-y-1">
              <h2 class="text-3xl font-bold">{{ insight.value }}</h2>
              <p class="text-sm text-bb-neutral-gray">{{ insight.title }}</p>
            </div>
          </div>
        </div>
      </div>

      <!--HOW YOU RANK-->
      <div class="text-bb-text-default px-5 py-6 bg-white w-full space-y-3 rounded-lg shadow-md">
        <div class="flex items-center h-full w-full">
          <div class="flex justify-evenly w-full">
            <div class="space-y-3 w-full pl-3">
              <p class="text-sm font-medium">How you rank</p>
              <div class="flex items-center justify-center w-11 h-11 bg-purple-0 rounded-full">
                <p class="text-2xl font-bold text-bb-brand-purple">{{ howYouRank.rank }}</p>
              </div>
              <p class="font-normal">In paid results</p>
            </div>
            <div class="space-y-3 w-full">
              <p class="text-sm font-medium">Top Competitors</p>
              <div v-if="yourCompetitors.length === 0">No competitors found</div>
              <div
                v-for="competitor in yourCompetitors.slice(0, 2)"
                v-else
                :key="competitor.name"
                class="w-fit text-bb-fuchsia-pink bg-bb-blush-pink text-xs font-bold py-1 px-2"
                style="border-radius: 4px"
              >
                <p>{{ competitor.name }}</p>
              </div>
            </div>
            <div class="h-full my-auto w-full text-center">
              <p class="text-sm font-medium">Protect your budget</p>
              <p class="text-center text-bb-neutral-gray text-xs">
                Automatically pause your campaign <br />
                when competition is low
              </p>
              <div class="pt-4">
                <MergeButtonRound
                  v-if="!monitoringJob.pauseCampaign"
                  button-type="brand-purple"
                  @click="showProtectBrandModal"
                >
                  <div class="flex items-center gap-2 justify-center mx-auto">
                    <div>
                      <ic-magic-wand />
                    </div>
                    <p class="w-40">Enable Smart Activation</p>
                  </div>
                </MergeButtonRound>
              </div>
              <div>
                <MergeButtonRound
                  v-if="monitoringJob.pauseCampaign"
                  button-type="secondary"
                  @click="updatePauseCampaignFlag(false)"
                >
                  <div class="flex items-center gap-2 justify-center mx-auto">
                    <div>
                      <ic-stop />
                    </div>
                    <p class="w-37">Stop Smart Activation</p>
                  </div>
                </MergeButtonRound>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--ROW 2-->
    <div class="flex gap-6 w-full h-100">
      <div class="w-2/5">
        <top-of-page-chart :competition-ranking="competitionRanking" />
      </div>
      <div class="w-3/5">
        <top-competitors
          v-if="topCompetitorsData.length > 0"
          :top-competitors="topCompetitorsData"
        />
      </div>
    </div>

    <!--ROW 3-->
    <div class="pb-10">
      <MostFrequentRank
        v-if="monitoringJob.id"
        :monitoring-job="monitoringJob"
      />
    </div>

    <!--MODAL-->
    <bb-base-modal
      v-if="modalConfig.modal"
      :width="modalConfig.width"
      :height="modalConfig.height"
      @close="closeModal"
    >
      <template #container>
        <protect-brand-modal
          @confirmed="updatePauseCampaignFlag"
          @close="closeModal"
        />
      </template>
    </bb-base-modal>
  </div>
</template>

<script>
// Icons
import IcMagicWand from '@/components/icon/brightbid/ic-magic-wand.vue'
import IcStop from '@/components/icon/ic-stop.vue'
import IcNormalPeople from '@/components/icon/brightbid/ic-normal-people.vue'
import IcPositivePeople from '@/components/icon/brightbid/ic-positive-people.vue'
import IcNegativePeople from '@/components/icon/brightbid/ic-negative-people.vue'
import IcDonutChart from '@/components/icon/brightbid/ic-donut-chart.vue'
import IcInfo from '@/components/icon/ic-info.vue'

// Components
import TopOfPageChart from '@/views/site/search/competitor_monitoring/overview/cards/TopOfPageChart.vue'
import ProtectBrandModal from '@/components/modals/ProtectBrandModal.vue'
import TopCompetitors from '@/views/site/search/competitor_monitoring/overview/cards/TopCompetitors.vue'
import MostFrequentRank from '@/views/site/search/competitor_monitoring/overview/cards/MostFrequentRank.vue'
import DialogBox from '@/views/site/search/competitor_monitoring/overview/cards/DialogBox.vue'
import InfringementBanner from '@/views/site/search/competitor_monitoring/overview/cards/InfringementBanner.vue'
import BbBaseModal from '@/components/modals/brightbid/BbBaseModal.vue'

import Toast from '@/components/shared/Toast.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import { getWebsiteFromURL } from '@/utils/url-util'
import BrightbidLoader from '@/components/loader/BrightbidLoader.vue'

export default {
  name: 'CompetitorMonitoring',
  components: {
    BrightbidLoader,
    IcMagicWand,
    IcNormalPeople,
    IcPositivePeople,
    IcNegativePeople,
    IcDonutChart,
    IcInfo,
    IcStop,
    TopOfPageChart,
    MostFrequentRank,
    TopCompetitors,
    DialogBox,
    InfringementBanner,
    Toast,
    BbBaseModal,
    ProtectBrandModal,
  },
  data() {
    return {
      modalConfig: { width: '', height: '', modal: null },
    }
  },
  computed: {
    ...mapState('site', ['selectedSite']),
    ...mapState('competitorMonitoring', [
      'competitorInsights',
      'monitoringJob',
      'competitionRanking',
      'topCompetitors',
      'savingsInsights',
      'isOverviewLoading',
      'infringementSummary',
    ]),
    ...mapGetters({ isMonitoringJobStopped: 'competitorMonitoring/isMonitoringJobStopped' }),
    hasSavingsInsights() {
      return (
        this.savingsInsights?.numberOfPauses > 0 ||
        this.savingsInsights?.totalTimeSavedSeconds > 0 ||
        this.savingsInsights?.totalMoneySaved?.value > 0
      )
    },
    computedCompetitorInsights() {
      return [
        {
          title: 'Competitors',
          value: this.competitorInsights?.totalCompetitors,
          icon: 'IcNormalPeople',
          iconColor: 'text-bb-brand-purple',
          iconBgColor: 'bg-bb-purple-50',
        },
        {
          title: 'New Entrants',
          value: `${this.competitorInsights?.entered > 0 ? '+' : ''}${this.competitorInsights?.entered}`,
          icon: 'IcPositivePeople',
          iconColor: 'text-bb-red-error',
          iconBgColor: 'bg-bb-red-50',
          iconClass: 'ml-2',
        },
        {
          title: 'Exits',
          value: `${this.competitorInsights?.left > 0 ? '-' : ''}${this.competitorInsights?.left}`,
          description: 'the auction the last 30 days',
          icon: 'IcNegativePeople',
          iconColor: 'text-bb-blue',
          iconBgColor: 'bg-bb-light-blue',
          iconClass: 'ml-2',
        },
      ]
    },
    howYouRank() {
      if (this.isOverviewLoading || this.competitionRanking.length === 0 || !this.competitionRanking) return []
      return this.competitionRanking.find(item => item.isOwn)
    },
    yourCompetitors() {
      const competitors = this.competitionRanking.filter(item => !item.isOwn)
      return competitors.map(item => {
        return {
          name: item.competitorName ?? getWebsiteFromURL(item.competitor),
          rank: item.rank,
        }
      })
    },
    topCompetitorsData() {
      const clonedTopCompetitors = structuredClone(this.topCompetitors)
      return clonedTopCompetitors.map(item => {
        return {
          competitor: item.competitorName ?? getWebsiteFromURL(item.competitor),
          url: item.competitor,
          ranks: item.ranks,
          marketSharePercentage: item.marketSharePercentage,
        }
      })
    },
  },
  methods: {
    ...mapActions('competitorMonitoring', ['setOverviewLoading', 'loadMonitoringJob']),
    ...mapActions('toast', ['loadToast']),
    showProtectBrandModal() {
      this.modalConfig = { width: '720px', height: '500px', modal: 'protect-your-brand' }
    },
    closeModal() {
      this.modalConfig = { width: '', height: '', modal: null }
    },
    async updatePauseCampaignFlag(pauseCampaign) {
      if (this.isMonitoringJobStopped) {
        await this.loadToast({
          title: 'Warning',
          message: "You're not allowed to enable the Smart Activation while the monitoring job is paused.",
          type: 'warning',
        })
        return
      }

      try {
        this.setOverviewLoading(true)
        this.closeModal()
        await this.$http.patch(
          `/cms/site/${this.selectedSite.value}/monitoring-job/${this.monitoringJob.id}/pause-campaign`,
          {
            pauseCampaign,
          },
        )
        const clonedMonitoringJob = structuredClone(this.monitoringJob)
        clonedMonitoringJob.pauseCampaign = pauseCampaign
        this.loadMonitoringJob(clonedMonitoringJob)
      } catch (error) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to enable the smart activation. Please try again later.',
          type: 'error',
        })
      } finally {
        this.setOverviewLoading(false)
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
