import psl from 'psl'

const removeProtocol = url => {
  return url.replace('http://', '').replace('https://', '')
}

const trimUrl = url => {
  return url.replace(/\/$/, '')
}

const getUrlHostname = url => {
  let finalUrl = removeProtocol(url)
  finalUrl = trimUrl(finalUrl)

  try {
    return psl.get(finalUrl) ?? finalUrl
  } catch (e) {
    return finalUrl
  }
}

export const getWebsiteFromURL = url => {
  const hostname = getUrlHostname(url)

  const website = hostname.split('.')[0]
  return website.charAt(0).toUpperCase() + website.slice(1)
}
