<template>
  <div class="h-16 bg-white flex justify-between items-center">
    <div class="flex justify-start items-center gap-3 h-full">
      <div class="w-1 h-full rounded-br-lg rounded-tr-lg bg-bb-red-error"></div>
      <div class="element bg-bb-red-100 h-8 w-8 flex items-center justify-center">
        <ic-bell class="text-bb-red-error" />
      </div>
      <p class="text-sm font-bold">{{ newCount }} new potential infringements have been identified!</p>
    </div>

    <div class="pr-4">
      <merge-button-round
        button-type="secondary"
        class="w-46"
        @click="$router.push({ name: 'infringements-detector' })"
      >
        <p>See infringements</p>
      </merge-button-round>
    </div>
  </div>
</template>

<script>
import IcBell from '@/components/icon/ic-bell.vue'

export default {
  name: 'InfringementBanner',
  components: { IcBell },
  props: {
    newCount: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style scoped lang="scss">
.element {
  border-radius: 9999px;
  transition: border-radius 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.element:hover {
  border-radius: 12px;
}
</style>
