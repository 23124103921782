import * as d3 from 'd3'

export const createDonutSvg = ({ chartID, data, colors, sizes }) => {
  let durations = {
    entryAnimation: 2000,
    labelFadeIn: 500,
  }

  const addLabels = chart => {
    const arc = d3
      .arc()
      .innerRadius(sizes.innerRadius * 1.5)
      .outerRadius(sizes.outerRadius)

    const labelGroup = d3
      .select(chartID)
      .append('g')
      .attr('transform', `translate(${sizes.width / 2},${sizes.height / 2})`)
      .selectAll('g')
      .data(chart)
      .enter()
      .append('g')
      .attr('transform', d => `translate(${arc.centroid(d)})`)

    labelGroup.append('circle').attr('r', 16).attr('fill', 'white').attr('stroke', '#E0E0E0').attr('stroke-width', 1)

    labelGroup
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('dy', '0.35em')
      .attr('stroke', '#080808')
      .attr('stroke-width', 0.2)
      .style('fill', '#080808')
      .style('font-size', '12px')
      .style('font-weight', 500)
      .text(d => `${d.data}%`)
      .style('opacity', 0)
      .transition()
      .duration(durations.labelFadeIn)
      .style('opacity', 1)
  }

  d3.select(chartID).html('')

  const generator = d3.pie().sort(null)
  const chart = generator(data.map(d => d.value))

  const arc = d3.arc().innerRadius(sizes.innerRadius).outerRadius(sizes.outerRadius)

  const tooltip = d3
    .select('body')
    .append('div')
    .attr('class', 'tooltip')
    .style('position', 'absolute')
    .style('visibility', 'hidden')
    .style('background', '#fff')
    .style('border', '1px solid #ccc')
    .style('padding', '5px')
    .style('border-radius', '4px')
    .style('box-shadow', '0 0 10px rgba(0,0,0,0.1)')
    .style('font-size', '12px')

  const arcs = d3
    .select(chartID)
    .append('g')
    .attr('transform', `translate(${sizes.width / 2},${sizes.height / 2})`)
    .attr('style', 'max-width: 100%; height: auto;')
    .selectAll('path')
    .data(chart)
    .enter()
    .append('path')
    .style('fill', (d, i) => colors[i])
    .attr('stroke', '#fff')
    .attr('stroke-width', 5)
    .on('mouseover', function (event, d) {
      d3.select(this).transition().duration(200).attr('stroke-width', 2)

      tooltip.style('visibility', 'visible').text(data[d.index].label)
    })
    .on('mousemove', function (event) {
      tooltip.style('top', event.pageY - 10 + 'px').style('left', event.pageX + 10 + 'px')
    })
    .on('mouseout', function () {
      d3.select(this).transition().duration(200).attr('stroke-width', 5)

      tooltip.style('visibility', 'hidden')
    })

  const angleInterpolation = d3.interpolate(generator.startAngle()(), generator.endAngle()())

  arcs
    .transition()
    .duration(durations.entryAnimation)
    .attrTween('d', d => {
      let originalEnd = d.endAngle
      return t => {
        let currentAngle = angleInterpolation(t)
        if (currentAngle < d.startAngle) {
          return ''
        }

        d.endAngle = Math.min(currentAngle, originalEnd)

        return arc(d)
      }
    })
    .on('end', (d, i, nodes) => {
      if (i === nodes.length - 1) {
        addLabels(chart)
      }
    })

  d3.select(chartID)
    .attr('width', sizes.width)
    .attr('height', sizes.width)
    .attr('style', 'max-width: 100%; height: auto;')
    .attr('viewBox', [0, 0, sizes.height, sizes.width])
    .transition()
    .duration(durations.entryAnimation)
    .tween('arcRadii', () => {
      return () => arc.innerRadius(sizes.innerRadius).outerRadius(sizes.outerRadius).cornerRadius(5)
    })
}
