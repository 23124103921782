<template>
  <div class="p-8 space-y-10">
    <div class="space-y-2">
      <h3 class="m-0 text-center text-gray-700 text-2xl font-medium">Protect your Budget</h3>
      <p class="text-center text-gray-700 text-base font-normal">
        Brightbid will continuously monitor your campaign and automatically pause or enable it when competition is low.
      </p>
    </div>

    <div>
      <div>
        <div class="px-5 rounded-lg border border-gray-300 justify-between items-center flex">
          <div class="py-2 flex-col justify-center items-start inline-flex">
            <div class="">
              <span class="text-gray-700 text-sm font-bold">Dynamic Pausing:</span
              ><span class="text-gray-700 text-sm font-normal">
                <br />Auto pause campaigns in response to potential threats or adverse condition</span
              >
            </div>
          </div>
          <div class="relative">
            <IcRoundChecked />
          </div>
        </div>
        <div class="px-5 my-4 rounded-lg border border-gray-300 justify-between items-center flex">
          <div class="py-2 flex-col justify-center items-start inline-flex">
            <div class="">
              <span class="text-gray-700 text-sm font-bold">Proactive Activation:</span
              ><span class="text-gray-700 text-sm font-normal">
                <br />Automatically reactivate paused campaigns when conditions are favorable.</span
              >
            </div>
          </div>
          <div class="relative">
            <icRoundChecked />
          </div>
        </div>
        <div class="px-5 rounded-lg border border-gray-300 justify-between items-center flex">
          <div class="py-2 flex-col justify-center items-start inline-flex">
            <div class="">
              <span class="text-gray-700 text-sm font-bold">Continuous Monitoring:</span
              ><span class="text-gray-700 text-sm font-normal">
                <br />It ensures ongoing monitoring to adapt to changes in the competitive landscape.</span
              >
            </div>
          </div>
          <div class="relative">
            <icRoundChecked />
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="flex justify-center gap-10">
        <merge-button-round
          button-type="tertiary"
          class="w-46"
          @click="$emit('close')"
        >
          Cancel
        </merge-button-round>
        <merge-button-round
          button-type="primary"
          class="w-46"
          @click="understood"
        >
          Confirm
        </merge-button-round>
      </div>
    </div>
  </div>
</template>

<script>
import IcRoundChecked from '@/components/icon/ic-round-checked'

export default {
  name: 'ProtectBrandModal',
  components: { IcRoundChecked },
  methods: {
    understood() {
      this.$emit('confirmed', true)
    },
  },
}
</script>
