import * as d3 from 'd3'

const COLORS = ['#38F1CA', '#65C8FF', '#AFADFF', '#FFB0C3']

export const createRankSvg = data => {
  const width = 88
  const height = 32

  const svg = d3
    .create('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', [0, 0, width, height])
    .attr('style', 'max-width: 100%; height: auto;')

  const graphArea = svg.append('g').attr('transform', `translate(0, 0)`)

  const x = d3
    .scaleBand()
    .rangeRound([0, width])
    .domain(data.map(d => d.rank))
    .padding(0.3)

  const y = d3
    .scaleLinear()
    .range([height, 0])
    .domain([d3.min(data, d => d.count) - 5, d3.max(data, d => d.count) + 5])

  graphArea.append('g').attr('class', 'axis').attr('transform', `translate(0, ${height})`)

  graphArea.append('g').attr('class', 'axis')

  const rx = 2
  const ry = 2

  graphArea
    .selectAll('bar')
    .data(data)
    .enter()
    .append('path')
    .style('fill', (d, i) => {
      return COLORS[i]
    })
    .attr(
      'd',
      item => `
        M${x(item.rank)},${y(item.count) + ry}
        a${rx},${ry} 0 0 1 ${rx},${-ry}
        h${x.bandwidth() - 2 * rx}
        a${rx},${ry} 0 0 1 ${rx},${ry}
        v${height - y(item.count) - ry}
        h${-x.bandwidth()}Z
      `,
    )
  return svg.node()
}
