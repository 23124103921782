<template>
  <div class="relative">
    <div class="gradient-border bg-bb-brand-purple w-full rounded-lg">
      <!--Caret-->

      <div class="flex flex-col lg:flex-row h-full gap-10 items-center px-10 py-7">
        <div class="w-full lg:w-87">
          <div class="flex w-full lg:w-87 justify-between items-center">
            <div class="space-y-3 max-w-60 w-full">
              <ic-magic-wand
                class="text-bb-brand-green"
                size="24"
              />
              <p class="font-medium text-base text-white">We are protecting your brand. How do we do it?</p>
            </div>
            <div>
              <ic-chevron
                class="text-bb-purple-200"
                direction="right"
                :size="30"
              />
            </div>
          </div>
        </div>

        <div class="w-full flex flex-col md:flex-row md:justify-between md:items-center space-y-4 md:space-y-0">
          <div>
            <p class="font-medium text-base text-white">We paused your campaign</p>
            <p class="font-bold text-3xl text-white">
              {{ savingsInsights.numberOfPauses }} <span class="font-medium text-base text-white">times</span>
            </p>
          </div>
          <div>
            <p class="font-medium text-base text-white">Time you saved</p>
            <div class="flex items-center gap-2">
              <ic-clock class="text-bb-brand-green" />
              <p class="font-bold text-3xl text-white">
                {{ totalHoursSaved }} <span class="font-medium text-base text-white">hrs</span>
              </p>
            </div>
          </div>
          <div class="pr-5">
            <p class="font-medium text-base text-white">You saved</p>
            <div class="flex items-center gap-2">
              <ic-dollar class="text-bb-brand-green p-0.5" />
              <p class="font-bold text-3xl text-white">
                {{ savingsInsights.totalMoneySaved.value.toLocaleString() }}
                <span class="font-medium text-base text-white">{{ savingsInsights.totalMoneySaved.currency }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="absolute right-10"
      style="bottom: -16px"
    >
      <svg
        width="39"
        height="17"
        viewBox="0 0 39 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.1739 16.0286C18.9298 16.6953 20.0637 16.6953 20.8197 16.0286L38.9936 0.000793457H0L18.1739 16.0286Z"
          fill="#646AF9"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import IcMagicWand from '@/components/icon/brightbid/ic-magic-wand.vue'
import IcChevron from '@/components/icon/ic-chevron.vue'
import IcClock from '@/components/icon/brightbid/IcClock.vue'
import IcDollar from '@/components/icon/ic-dollar.vue'

export default {
  name: 'DialogBox',
  components: { IcChevron, IcMagicWand, IcClock, IcDollar },
  props: {
    savingsInsights: {
      type: Object,
      default: () => ({
        numberOfPauses: 0,
        totalTimeSavedSeconds: 0,
        totalMoneySaved: { value: 0, currency: 'SEK' },
      }),
    },
  },
  computed: {
    totalHoursSaved() {
      return parseFloat((this.savingsInsights.totalTimeSavedSeconds / 3600).toFixed(2))
    },
  },
}
</script>

<style scoped lang="scss">
.gradient-border {
  box-sizing: border-box; /* Ensure the border is included in the element's dimensions */
  border: double 2px transparent;
  border-radius: 8px;
  background-image: linear-gradient(102.89deg, #9496ff -20.43%, #6366fa 64.92%),
    radial-gradient(circle at top left, #84fae4, #6366fa);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
