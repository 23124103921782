<template>
  <div class="text-bb-text-default px-6 py-4 bg-white rounded-lg shadow-md w-full h-100">
    <div class="flex items-center justify-between">
      <div class="flex items-center gap-4">
        <div class="h-10 w-10 rounded-lg bg-neutral-0 text-bb-brand-purple">
          <ic-donut-chart class="my-auto mx-auto h-full" />
        </div>
        <h5 class="h5 text-bb-text-default">Share of absolute top of search</h5>
      </div>

      <new-tooltip direction="bottom-start">
        <ic-info class="text-bb-disabled-buttons" />
        <template #content>
          <div class="w-48">
            <p>This chart displays #1 paid ranking share for your brand keywords.</p>
          </div>
        </template>
      </new-tooltip>
    </div>
    <div
      class="flex items-center gap-4"
      style="height: 344px"
    >
      <!--DONUT CHART-->
      <div>
        <svg id="doughnut-chart"></svg>
      </div>

      <!--LEGENDS-->
      <div class="space-y-3">
        <div
          v-for="(competitor, index) in topOfPageChartData"
          :key="index"
          class="flex gap-3"
        >
          <div
            class="w-2 h-5 rounded-full"
            :style="{ backgroundColor: LEGEND_COLORS[index] }"
          ></div>
          <p
            :class="{ 'text-bb-brand-purple font-bold': competitor.isOwn }"
            class="max-w-40 truncate"
          >
            {{ competitor.label }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createDonutSvg } from '@/views/site/search/competitor_monitoring/overview/doughnut-chart'

import IcInfo from '@/components/icon/ic-info.vue'
import IcDonutChart from '@/components/icon/brightbid/ic-donut-chart.vue'
import NewTooltip from '@/components/alert/NewTooltip.vue'
import { getWebsiteFromURL } from '@/utils/url-util'

const LEGEND_COLORS = ['#6366FA', '#84FAE4', '#ACACFD', '#A3CDFE', '#FFA776', '#FFDB76']

export default {
  name: 'TopOfPageChart',
  components: { IcDonutChart, IcInfo, NewTooltip },
  props: {
    competitionRanking: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      donut: null,
      LEGEND_COLORS,
    }
  },
  computed: {
    topOfPageChartData() {
      const topOfPageChartData = this.competitionRanking.map(item => {
        return {
          label: item.competitorName ?? getWebsiteFromURL(item.competitor),
          value: item.topPagePercentage,
          rank: item.rank,
          isOwn: item.isOwn,
        }
      })

      // move the own website to the top of the list
      const index = topOfPageChartData.findIndex(item => item.isOwn === true)

      if (index > -1) {
        const element = topOfPageChartData.splice(index, 1)[0]
        topOfPageChartData.unshift(element)
      }

      if (topOfPageChartData.length > 6) {
        const lowestRank = this.competitionRanking.length + 1
        const others = topOfPageChartData.splice(5)
        const sum = others.reduce((accumulator, current) => accumulator + current.value, 0)

        topOfPageChartData.push({
          label: 'Others',
          value: sum,
          isOwn: false,
          rank: lowestRank,
        })
      }
      return topOfPageChartData
    },
  },
  watch: {
    competitionRanking() {
      this.createChart()
    },
  },
  mounted() {
    this.createChart()
  },
  methods: {
    createChart() {
      const chartData = {
        chartID: '#doughnut-chart',
        data: this.topOfPageChartData,
        colors: LEGEND_COLORS,
        sizes: { innerRadius: 60, outerRadius: 110, height: 240, width: 240 },
      }
      createDonutSvg(chartData)
    },
  },
}
</script>

<style scoped lang="scss"></style>
