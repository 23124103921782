import { render, staticRenderFns } from "./MostFrequentRank.vue?vue&type=template&id=519ec563&scoped=true"
import script from "./MostFrequentRank.vue?vue&type=script&lang=js"
export * from "./MostFrequentRank.vue?vue&type=script&lang=js"
import style0 from "./MostFrequentRank.vue?vue&type=style&index=0&id=519ec563&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "519ec563",
  null
  
)

export default component.exports