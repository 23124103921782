<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  name: 'line-chart',
  mixins: ['reactiveProp'],
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  watch: {
    chartData() {
      this._data._chart.destroy()
      this.renderChart(this.chartData, this.options)
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  },
}
</script>
