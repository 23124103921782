<template>
  <div>
    <div class="chartCard mt-4">
      <div class="flex">
        <div class="flex gap-2 items-center">
          <div>
            <IcCharts />
          </div>
          <div class="text-gray-700 text-base font-medium my-auto pl-2">Most frequent rank</div>
          <new-tooltip direction="bottom-start">
            <ic-info class="text-bb-disabled-buttons" />
            <template #content>
              <div class="w-70">
                <p>
                  See the most frequent rank over time per advertiser. Use the dropdown to switch between advertisers
                  bidding on your keywords.
                </p>
              </div>
            </template>
          </new-tooltip>
        </div>
        <div
          v-if="competitors?.length"
          class="ml-auto flex"
        >
          <div>
            <SearchInput
              :value="selectedCompetitor"
              placeholder="Select competitor..."
              :options="competitors"
              :input-id="MostFrequentRankSelectBox"
              class="w-full"
              @select-item="selectCompetitor"
            />
          </div>
          <div class="text-gray-700 text-base font-normal my-auto ml-10">Today</div>
        </div>
      </div>
      <div class="chartHeight mb-4 mt-3">
        <LineChart
          :chart-data="chartData"
          :options="options"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NewTooltip from '@/components/alert/NewTooltip.vue'
import IcInfo from '@/components/icon/ic-info.vue'
import IcCharts from '@/components/icon/ic-charts.vue'
import SearchInput from '@/components/input/brightbid/SearchInput.vue'
import LineChart from '@/components/chart/base/LineChart.vue'
import { mapState } from 'vuex'

export default {
  name: 'MostFrequentRank',
  components: {
    IcCharts,
    SearchInput,
    LineChart,
    NewTooltip,
    IcInfo,
  },
  props: {
    monitoringJob: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                color: 'rgba(0,0,0,0.5)', // Change opacity to 50%
              },
              gridLines: {
                color: 'rgba(0,0,0,0)', // Lighter grid lines
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                color: 'rgba(0,0,0,0)', // Fully transparent ticks
                reverse: true, // Invert the y-axis
                callback: function (value) {
                  // Force tick labels to be integers
                  return Number.isInteger(value) ? value : ''
                },
              },
              gridLines: {
                color: 'rgba(0,0,0,0.04)', // Very light grid lines
                borderDash: [5, 5], // Sets dashed grid lines: 5px dash, 5px gap
                // drawOnChartArea: false,
              },
            },
          ],
        },
        elements: {
          line: {
            backgroundColor: 'rgba(99, 102, 251, 0.2)', // Background color under the line chart
          },
        },
      },
      competitors: [],
      competitorsData: {},
      MostFrequentRankSelectBox: 'MostFrequentRankSelectBox',
      selectedCompetitor: null,
    }
  },
  computed: {
    ...mapState('site', ['selectedSite']),
    ...mapState('competitorMonitoring', ['frequencyRanks']),
  },
  async mounted() {
    this.competitorsData = structuredClone(this.frequencyRanks)
    this.competitors = Object.keys(this.competitorsData).map(item => ({ value: item, label: item }))
    this.selectCompetitor(this.competitors[0])
  },
  methods: {
    selectCompetitor(competitor) {
      if (!competitor) return
      const data = this.competitorsData[competitor.value].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
      const newChart = {
        labels: data.map(item =>
          new Date(item.timestamp).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false, // Explicitly set to false to enforce 24-hour format
          }),
        ),
        datasets: [
          {
            data: data.map(item => item.rank),
            fill: 'start',
            borderColor: '#6366FB',
            borderWidth: 2,
            backgroundColor: ctx => {
              const canvas = ctx.chart.ctx
              var gradient = canvas.createLinearGradient(0, 0, 0, 400)
              gradient.addColorStop(0, '#6366FB')
              gradient.addColorStop(1, 'rgba(218, 218, 255, 0)')
              return gradient
            },
          },
        ],
      }
      this.chartData = newChart
      this.selectedCompetitor = competitor
    },
  },
}
</script>

<style scoped lang="scss">
.chartCard {
  background: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  overflow-x: auto;
  box-shadow: 0px 7px 12px 0px rgba(136, 152, 170, 0.1490196078);
}

.chartHeight {
  height: 360px;
}
</style>
